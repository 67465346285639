<template>
  <div class="ticket">
    <div class="wrap indexbody">
      <!-- <header>
        <div class="headleftl" style="cursor: pointer">
          <img src="../assets/newticket/daohang2.png" alt="" />
        </div>
        <div class="headleft" style="cursor: pointer">
          <img src="../assets/newticket/logo2.png" alt="" />
        </div>
        <div class="headcent" style="cursor: pointer">
          <ul>
            <li>票务预定</li>
          </ul>
        </div>
      </header> -->
      <div class="cent">
        <div class="center">
          <div class="centhead" style="cursor: pointer">
            <dl>
              <dd>
                <!-- Swiper -->
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <img src="../assets/newticket/0.jpg" alt="" />
                    </div>
                    <div class="swiper-slide">
                      <img src="../assets/newticket/2.png" alt="" />
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </dd>
 <dt>
                            <h4>顺义博物馆</h4>
                            <ul>
                                <li>
                                     <span>开放时间:</span>
                                    <div class="pc-show">
                                       <span>09:00--17:30</span><span class="spans">详情</span>
                                    </div>
                                    
                                     <div class="mobile-show">
                                        <span>本馆周一为闭馆日<br/>周二至周五开闭馆时间09：00-17：30（17：00停止入馆）<br/>周六周日开闭馆时间09：00-20：00（19：30停止入馆）</span>
                                    </div>
                                </li>
                                <!-- <li><span>闭馆通知:</span><span>根据疫情防控要求，顺义区博物馆自2022年5月1日起暂停对外开放，具体恢复时间另行通知。因临时闭馆给大家带来的不便深表歉意！感谢理解与支持！ </span></li> -->
                                <li><span>团体预约电话:</span><span class="subscribe">81475286转1</span></li>
                                <li><span>团体预约时间:</span><span>周二至周日9:00-11:00、13:00-17:00</span></li>
                                <li><span>详情地址:</span><span>北京市顺义区石园街道石园南大街顺义文化中心内(南侧第二栋)</span></li>
                                </li>
                            </ul>
                            
                            <div class="show">
                                <span>
                                    本馆周一为闭馆日<br/>
                                    周二至周五开闭馆时间09：00-17：30（17：00停止入馆）<br/>
                                    周六周日开闭馆时间09：00-20：00（19：30停止入馆）<br />
                                    国家法定节假日具体开放时间安排以公告为准。
                                </span>
                            </div>
                        </dt>
            </dl>
          </div>
        </div>
      </div>
      <div class="cent" style="margin-top: 0px">
        <div class="center">
          <div class="visit" style="height: 1px;">
            <div class="visitcan" style="display: none;">
              <span>参观预约</span><span class="vist">参观须知</span
              ><span class="vists"></span>
            </div>
            <div class="visitlianx" style="display: none;">
              请仔细核对填写联系人、手机号、身份证件等信息
            </div>
          </div>
          <!-- 20240801修改@dou -->
          <div class="xuanzhe" style="display: none;">
            <div class="visitcans">
              <span>选择参观日期</span
              ><img src="../assets/newticket/tixing.png" alt="" /><span
                >请先选择使用日期</span
              >
            </div>
            <div class="datelist">
              <ul>
                <li
                  class="cursor-pointer"
                  v-for="(date, index) in dateList"
                  :key="index"
                  :class="{ lists: date.date === currentDate }"
                >
                  <div
                    :class="{
                      yupioa: date.status === 3,
                      yiman: date.status === 1 || date.status === 2,
                    }"
                    @click="dates(date)"
                  >
                    <span>{{ date.date }} {{ date.week }}</span>
                    <span v-if="(date.total_nums == '闭馆')">闭馆</span>
                    <span v-else-if="date.order_nums == date.total_nums || date.order_nums > date.total_nums">已满</span>
                    <span v-else>余票{{ date.yupiao }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="youke">
              <span>游客信息填写</span
              ><span>为了您能顺利出行，请务必确认所填姓名与所持证件一致</span>
            </div>
            <div class="shuru">
              <div class="shutop">
                <div class="shuname">
                  <span>游客姓名</span>
                  <input
                    type="text"
                    placeholder="请输入姓名"
                    id="name"
                    v-model="username"
                  />
                </div>
                <div class="shuname">
                  <span>联系电话</span>
                  <input
                    type="text"
                    id="phone"
                    placeholder="请输入联系电话"
                    maxlength="11"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="shutop">
                <div class="shuzheng">
                  <span>证件类型</span>
                  <select id="zjType" v-model="selectClassEnd" @change="selectClass($event)">
                    <option v-for="(options,id) in selectClassData" :key="id" :value="options.value">
                        {{options.label}}
                    </option>
                </select>
                <div class="xiaLa"></div>

                  <!--<input type="text" value="" disabled="disabled" />-->
                </div>
                <div class="shuname">
                  <span>证件号码</span>
                  <input
                    type="text"
                    id="CardNo"
                    placeholder="请输入证件号码"
                    maxlength="18"
                    onKeyUp="value=value.replace(/[\W]/g,'')"
                    v-model="userId"
                  />
                </div>
              </div>
              <div class="btn" @click="yuyue">立即预约</div>
            </div>
          </div>
          <div class="bord">
            <div class="xuzhi">
              <img src="../assets/newticket/huawens.png" class="gusu" alt="">
              <img src="../assets/newticket/xuzhi.png" alt="" class="imgs" />
              <ul>
                <li>
                  <img src="../assets/newticket/houdong (1).png" alt="" />
                  <!-- <span>一张证件只能预约1人，每个身份证号每日仅可预约1次，超过3次预约不来参观，将取消在线预约资格，可前往馆内预约。</span> -->
                  <span>自2024年8月1日起实行免预约入馆参观，您可直接到馆，通过闸机经安检后有序进入。</span>
                </li>
                <!-- <li>
                  <img src="../assets/newticket/houdong (1).png" alt="" />
                  <span>馆内目前只开放二层顺义历史文化展厅，其余展厅暂停对外开放。限量预约开放期间，观众须在入口处完成体温检测，出示北京健康宝，出示本人二代身份证（未办理身份证的儿童请监护人做好游玩陪同措施）验证预约信息，入馆参观群众需提供72小时内的核酸结果，方可进入馆区。</span
                  >
                </li> -->
                <li>
                  <img src="../assets/newticket/houdong (1).png" alt="" />
                  <!-- <span>现役军人、退伍军人、残疾人士出示相关证件可使用绿色通道进馆，孕妇及未成年登记后即可入馆。</span> -->
                  <span>团队参观仍需预约，请提前拨打预约咨询电话：81475286转1预约参观。</span>
                </li>
                
              </ul>
            </div>
            <div class="fuwu">
              <img src="../assets/newticket/huawens.png" alt="" class="gusu">
              <img src="../assets/newticket/jiangjie.png" alt="" class="imgs" />
              <div>
                <span>一：非预约讲解服务</span>
                <span>上午：9：30 | 10：30 下午：15：00 | 16：00</span>
              </div>
              <div class="jiangjiefuwu">
                <span>二：预约讲解服务</span>
                <span
                  >预约电话：81475286转1，电话预约时间：周二至周五（9:00-11:00
                  14:00-17:00）</span
                >
                <span
                  >通过电话的方式告诉您的预约内容（具体时间、单位名称、
                  参观人数、联系电话、联系人)</span
                >
              </div>
            </div>
          </div>
          <div class="ditu">
            <dl>
              <dt>
                <div id="allmap" style="width: 658px; height: 500px"></div>
              </dt>
              <dd>
                <div>交通路线</div>
                <div>
                  <span>详情地址</span>
                  <span
                    >北京市顺义区石园街道石园南大街顺义文化中心内(南侧第二栋)</span
                  >
                </div>
                <div>
                  <span>乘车路线</span>
                  <span class="dito-i"
                    >1. 乘顺2路到老干部局站下车，向西步行至红绿灯路口再向南步行200米左右到达顺义文化中心西门<br>
                      2. 乘顺13路到电信大楼站下车，向北步行200米左右到达顺义文化中心西门<br>
                      3.  乘顺5路到电信大楼站下车，向东步行至红绿灯路口过马路后再向北步500米左右到达顺义文化中心西门 <br>
                      请注意：顺13和顺5路虽然到达的站名字一样 ，但是站的位置不一样。文化中心院内暂不对外提供车位，建议广大游客绿色出行</span
                  >
                </div>
                <div class="backgr">

                </div>
                <!-- <img src="../assets/newticket/huawens.png" alt=""> -->
              </dd>
            </dl>
          </div>
        </div>
        <div class="centers">
          <div class="introduction">
            <div class="introductionhaed">
              <img src="../assets/newticket/introduction.png" alt="" />
            </div>
            <div class="pinwei">
              <dl>
                <dd>
                  <img src="../assets/newticket/biaoti22.png" alt="" />
                  <span
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;顺义博物馆是顺义区首家区级博物馆，馆内总建筑面积7656平方米，馆内设有大型下沉式多媒体沙盘、文创产品区、互动区、历史文化展厅、临展厅、多媒体功能厅、文物库房等。</span
                  >
                </dd>
                <dt>
                  <img src="../assets/newticket/2.png" alt="" />
                </dt>
              </dl>
            </div>
            <div class="jingxuan">
              <dl>
              <div id="huawen"> </div>
                <dt>
                  <img src="../assets/newticket/3.png" alt="" />
                </dt>
                <dd>
                  <ul>
                    <li>每一件藏品代表一个故事</li>
                    <li class="huawen-a">
                      每一件文物都是<span>中华文明和顺义文化</span>  的讲述者
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div class="through">
              <ul class="throughleft">
                <li>让我们携手迈入顺义时空之门，</li>
                <li>一起探索<span>古今的顺义</span></li>
              </ul>
              <div>
                <img src="../assets/newticket/houdong (2).png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="canguan">
          <img src="../assets/newticket/guanbi.png" alt="" class="canguans" />
          <h4>参观须知</h4>
          <ul>
            <li>
              (1)开放方式为预约参观。每位观众均须提前在顺义数字博物馆网站进行实名预约。顺义好礼开放时间为周二至周日 上午9:00-11:30。
            </li>
            <li>
                  (2)观众在顺义博物馆西门入口处凭预约码进馆。外籍人员预约到馆参观需携带护照。
            </li>
            <li>
                  (3)观众进入馆区后，请自觉遵守本馆有关规定，配合工作人员的引导及管理。

            </li>
            <li>
                  (4)若您参观时出现身体不适等情况，请及时联系就近工作人员。
            </li>        
          </ul>
        </div>
        <div class="successful">
          <img
            src="../assets/newticket/guanbi.png"
            alt=""
            class="successfuls"
          />
          <H4>预约成功！</H4>
          <span style="display: block; margin-top: 15px"
            >稍后我们将以短信的方式通知</span
          >
          <span style="display: block; padding-top: 5px">您的凭证码</span>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>


<script>
import footerCom from "../components/footer.vue";
import $ from "../components/index-page/jquery-3.3.1.min.js";
// import {} from "../components/index-page/fastclick.js";
import Swiper from "../components/index-page/swiper-bundle.min.js";
import { fetch, post } from '../api/https';
export default {
  components: {
    footerCom,
  },
  data() {
    return {
      dateList: [],
      currentDate: "",
      username: "",
      phone: "",
      userId: "",
      num:0,
      selectClassData:[{id: 0,label: '身份证',value:1},{id: 1,label: '护照',value:2}],
      select_class_id: 1,
      selectClassEnd:1
    };
  },
  async mounted() {
    let dateList = await fetch("/ticket_date_list");
    this.dateList = dateList.data;
            fetch('/getmenu')
                .then(res => {
                  document.title = res.data[2].menu_seo_title
                })
    // localStorage.clear();

    //消息提示
    sessionStorage.removeItem('date')
    
    function popToast(tipTxt, time) {
      var showTime = time != null || time != undefined ? time : 2000;
      var $popToast = $(".pop-toast");
      var poptimeout = "";
      $popToast.remove();
      var popToastHtml =
        '<div class="pop-toast"><div class="toast-tip">' +
        tipTxt +
        "</div></div>";
      $popToast = $(popToastHtml);
      $("body").append($popToast);
      if (poptimeout != "") {
        clearTimeout(poptimeout);
      }
      poptimeout = setTimeout(function () {
        $popToast.fadeOut();
      }, showTime);
    }

    $(".vist").click(function () {
      $(".canguan").show();
    });
    $(".vists").click(function () {
      $(".canguan").show();
    });
    $(".canguans").click(function () {
      $(".canguan").hide();
    });
    $(".successfuls").click(function () {
      $(".successful").hide();
    });
    // 验证中文名称
    function isChinaName(name) {
      var pattern = /^[\u4E00-\u9FA5]{1,6}$/;
      return pattern.test(name);
    }
    
    // 验证手机号
    function isPhoneNo(phone) {
      var pattern = /^1[34578]\d{9}$/;
      return pattern.test(phone);
    }
    
    // 验证身份证
    function isCardNo(card) {
        var zjType = $("#zjType").val()
        if(zjType == 2){
            //    
            ///^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/
            var pattern = /^[a-zA-Z0-9]{9}$/;
        }else{
            var pattern = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
        }
      return pattern.test(card);
    }

    $(".btn").click(function () {
      if(sessionStorage.getItem('token') != 'null'){ 
        if (sessionStorage.getItem('date') != null) {
          if (isChinaName($("#name").val())) {
            if (isPhoneNo($("#phone").val())) {
              if (isCardNo($("#CardNo").val())) {
                var obj = {
                    token:sessionStorage.getItem('token'),
                    source:'pc',
                    name: $("#name").val(),
                    phone: $("#phone").val(),
                    card_num: $("#CardNo").val(),
                    datas: sessionStorage.getItem('date'),
                    card_type:$("#zjType").val(),
                }
                post('/orderSave',obj).then((result) => {
                  popToast(result.msg)
                })
              } else {
                if($("#zjType").val() == 2){
                    popToast("请输入正确护照");
                }else{
                    popToast("请输入正确身份证号");
                }
              }
            } else {
              popToast("请输入正确手机号");
            }
          } else {
            popToast("请输入正确姓名");
          }
        } else {
          console.log(sessionStorage.getItem('date') != 'null');
          popToast("请选择参观日期");
        }
      }else{
        popToast("您还未登陆");
      }
    });

    var map = new BMapGL.Map("allmap");
    var point = new BMapGL.Point(116.677106,40.116104);
    
    map.centerAndZoom(point, 17);
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    var marker = new BMapGL.Marker(point); // 创建标注
    map.addOverlay(marker); // 将标注添加到地图中
    var opts = {
      width: 200, // 信息窗口宽度
      height: 90, // 信息窗口高度
      title: "顺义博物馆", // 信息窗口标题
      message: "这里是顺义博物馆",
    };
    var infoWindow = new BMapGL.InfoWindow(
      "地址：北京市顺义区石园街道石园南大街顺义文化中心内</br>电话：010-88866686",
      opts
    ); // 创建信息窗口对象
    map.openInfoWindow(infoWindow,point)
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });
    //banner轮播
    var swiper = new Swiper(".swiper-container", {
      direction: "horizontal",
      //播放速度
      loop: true,
      // 自动播放时间
      autoplay: true,
      // 播放的速度
      speed: 2000,
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    $(".spans")
      .mouseover(function () {
        $(".show").show();
      })
      .mouseout(function () {
        $(".show").hide();
      });
  },
  methods:{
    yuyue(res){
      if(sessionStorage.getItem('token')){
          console
          popToast("您还未选择日期");
      }
        
    },
    dates(res) {
      this.num++
	  if(res.total_nums == '闭馆' || (res.order_nums >= res.total_nums)){
		  return;
	  }

      if(localStorage.getItem('token')){
        this.currentDate = res.date
		sessionStorage.setItem('date',res.day)
      }else{
          this.showPopupMessage('请您先登录后预约')
          var that = this;
        function goskips(){
          that.$router.push('/login')
        }
          setTimeout(goskips, 2000);
      } 


    },
    selectClass(event){
      this.select_class_id = event.target.value; //获取option对应的value值 select_class_id是后台约定的提交数据的名称
     },
  }
};
</script>

<style >
.ticket .swiper-pagination-bullet-active {
  background: #fff !important;
}
</style>
<style>
</style>
<style scoped>
@import "../components/index-page/css/layout.css";
@import "../components/index-page/css/swiper-bundle.min.css";
>>>.BMap_bubble_title{
  font-size: 16px;
}
.pc_margin{
  margin-left: 1px !important;
}
.ticket {
  padding-top: 21px;
  background: #f9f9f9;
  font-family: "MicrosoftYaHei";
}
.backgr{
  width: 490px;
  height: 243px;
  background: url('../assets/newticket/huawens.png');
  position: absolute;
  top: 249px;
}
.dito-1{
  display: block;
  font-size: 17px;
  display: block;
  line-height: 2px;
  position: absolute;
  z-index: 10000;
}
.wrap {
  background: #f9f9f9;
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
}
a {
  color: #ffffff;
  text-decoration: none;
}
.cent {
  width: 100%;
  overflow: hidden;
}

header {
  background: #fff;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 999;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.jiangjiefuwu{
     margin-left: 40px;
     margin-top: 35px;
     z-index: 10000;
     position: absolute;
}
.headleft {
  /* height: 100%; */
  width: 185px;
  display: flex;
  height: 50px;
  margin-top: 22px;
  margin-left: 120px;
}

.headleft img {
  width: 250px;
}

.headleftl {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.1);
}

.hui {
  display: none;
  width: 100px;
}

.headleftl img {
  width: 34px;
  height: 20px;
  position: relative;
  top: 28px;
  left: 19px;
}

.headleftimg {
  margin-top: 22px;
  margin-left: 40px;
  width: 50px;
  height: 50px;
}

.headleftt span:nth-child(1) {
  margin-left: 12px;
  margin-top: 19px;
  color: #fff;
  font-size: 24px;
  display: block;
  font-weight: 500;
}

.headleftt span:nth-child(2) {
  margin-left: 12px;
  color: #000;
  font-size: 17px;
}

.headcent {
  flex: 1;
  text-align: center;
}

.headcent ul {
  margin-left: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 80px;
}

.headcent ul li:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.cent {
  width: 100%;
  height: auto;
}

.center {
  width: 1288px;
  margin: 0 auto;
  height: auto;
  /* margin-bottom: 60px!important; */
  background: #fff;
  padding-bottom: 77px;
}
.centers {
  width: 1288px;
  margin: 0 auto;
  height: auto;
  background: #fff;
}
.centhead {
  width: 1230px;
  height: 440px;
  margin: 0 auto;
}

.centhead dl {
  padding-top: 40px;
  width: 100%;
  height: 394px;
  display: flex;
  flex-wrap: wrap;
}

.centhead dl dd {
  width: 700px;
  height: 394px;
}

.displatLeft{
  display: none;
}

.mode_margin{
  /* margin: 0 !important; */
  margin-left: -12px !important;
}
.centhead dl dd img {
  width: 700px;
  height: 394px;
}

.centhead dl dt {
  margin-left: 50px;
  margin-top: 28px;
  width: 480px;
  height: 350px;
  position: relative;
}

.centhead dl dt h4 {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.centhead dl dt ul {
  width: 460px;
  margin-top: 40px;
}

.centhead dl dt ul li {
  color: #666666;
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}

.centhead dl dt ul li span:nth-child(1) {
  color: #999999;
  width: 98px;
  display: block;
}

.centhead dl dt ul li:nth-child(1) span:nth-child(3) {
  color: #b26c30;
  padding-left: 12px;
}

.centhead dl dt ul li:nth-child(2) span:nth-child(2) {
  padding-right: 7px;
}
.shijianclass {
  /* padding-left: 12px; */
  margin-left: -12px !important;
}
.centhead dl dt ul li:nth-child(4) span:nth-child(2) {
  /* padding-left: 12px; */
  /* margin-left: -24px; */
}

.btn {
  /* margin-top: 70px; */
  width: 477px;
  height: 55px;
  background: #e70012;
  text-align: center;
  line-height: 55px;
  font-size: 22px;
  color: #fff;
  margin: 70px auto 0;
  cursor: pointer;
}

.swiper-pagination-bullet {
  background: #827c77;
}

.show {
  position: absolute;
  top: 8px;
  left: 240px;
  width: 265px;
  height: 250px;
  background: #6a6a6a;
  border-radius: 5px;
  display: none;
}

.show span {
  padding-top: 20px;
  width: 230px;
  height: 200px;
  margin: 0 auto;
  display: block;
  color: #fff;
  font-size: 12px;
  line-height: 30px;
}

.introduction {
  /* padding-top: 90px; */
  width: 1150px;
  margin: 0 auto;
}

.introductionhaed {
  margin: 0 auto;
  text-align: center;
}

.introductionhaed img {
  /* width: 260px; */
}

.pinwei {
  padding-top: 60px;
  width: 1150px;

  margin: 0 auto;
}

.pinwei dl {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pinwei dl dd {
  flex: 1;
  /* width: 490px; */
}

.pinwei dl dd img {
  margin-left: 50px;
  /* width: 450px; */
  display: block;
}

.pinwei dl dd span {
  text-indent: 2em;
  line-height: 34px;
  display: block;
  width: 490px;
  color: #666666;
  font-size: 20px;
  margin-top: 50px;
}

.pinwei dl dt {
  width: 610px;
  height: 320px;
  overflow: hidden;
}

.pinwei dl dt img {
  width: 610px;
  height: 100%;
}

.jingxuan {
  width: 1160px;
  margin: 0 auto;
}

.jingxuan dl {
  width: 100%;
  display: flex;
  position: relative;
}

.jingxuan #huawen{
  background: url('../assets/newticket/huawens.png');
  position: absolute;
  right: -25px;
  bottom: 26px;
  width: 446px;
  height: 238px;
}

.jingxuan dl dt {
  width: 667px;
  z-index: 10000;
}

.jingxuan dl dt img {
  position: relative;
  top: -20px;
  width: 667px;
}

.jingxuan dl dd {
  margin-top: 250px;
  /* width: 520px; */
  flex: 1;
  text-align: center;
  color: #666666;
  font-size: 20px;
}

.jingxuan dl dd ul li {
  color: #000;
  line-height: 35px;
}

.jingxuan dl dd span {
  color: #aa5911;
}

.through {
  padding-top: 70px;
  width: 1180px;
  margin: 0 auto;
  display: flex;
}

.throughleft {
  margin-top: 110px;
  flex: 1;
  /* width: 380px; */
  text-align: center;
  color: #666666;
  font-size: 20px;
}

.throughleft span {
  color: #aa5911;
}

.chankan {
  width: 100%;
  height: 310px;
  text-align: center;
}

.chankan img {
  margin-top: 110px;
  width: 244px;
}

/* footer {
  width: 100%;
  height: 240px;
  background: #a78d63;
  margin-top: 110px;
  position: relative;
} */

/* footer p img {
  width: 18px;
} */

.header {
  text-align: left;
}

/* .wang {
  padding-top: 80px;
  width: 250px;
  height: 30px;
  display: flex;
  margin: 0 auto;
} */

/* .wang span {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.wang ul {
  margin-left: 10px;
  display: flex;
} */

/* .wang ul li {
  margin: 0 0 0 6px;
  width: 20px;
  height: 28px;
  background: #b09973;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
} */

/* footer p {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding-top: 10px;
}

footer p:nth-child(1) {
  padding-top: 40px;
}

.erweima {
  width: 240px;
  display: flex;
  position: absolute;
  top: 80px;
  right: 367px;
}

.erweima div {
  text-align: center;
  width: 120px;
  font-size: 10px;
  color: #ded4c4;
} */

/* .erweima div span {
  line-height: 30px;
}

.erweima div img {
  margin-left: 23px;
  width: 74px;
  height: 74px;
  display: block;
} */

@media screen and (max-width: 1200px) {
  .new_hideen{
    display: none !important;
  }
  .mode_margin{
  margin-left: 2px !important; 
}
  .cent {
    margin-top: 0px;
  }
  .ticket{
        margin-left: 34px;
    transform: scalex(1.05);  
  }
  .news_shijian{
    padding-left: 1px !important;
  }
  /* top */
  .headcent ul li:nth-child(1) {
    font-size: 24px;
  }

  /* banner */
  .center {
    width: 100%;
  }

  .centhead {
    width: 100%;
    height: 1425px;
  }

  .centhead dl dd {
    width: 100%;
  }

  .centhead dl dd img {
    width: 100%;
    height: 500px;
  }
  .centhead dl dt h4 {
    visibility: hidden;
  }
  .centhead dl {
    height: 980px;
    /*height: 1050px;*/

    /* height: 700px; */
    display: block;
  }

  .centhead dl dt {
    width: 100%;
  }

  .centhead dl dt ul {
    width: 100%;
    margin-top: 50px;
  }

  .centhead dl dt h4 {
    text-align: center;
  }

  .btn {
    margin: 30px auto;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
  }

  .pc_margin{
    margin: 0;
  }

  .centhead dl dt h4 {
    /* font-size: 36px; */
    font-size: 52px;
  }

  .centhead dl dt ul li {
    /* font-size: 24px; */
    font-size: 42px;
    line-height: 81px;
    padding-right: 50px;
  }

  .centhead dl dt ul li span:nth-child(1) {
    width: auto;
    min-width: 200px;
    margin-right: 6px;
  }
  .centhead dl dt ul li:nth-child(4) span:nth-child(2) {
    padding-left: 0 !important; 
    width: 966px;
  }
  /* 展馆介绍 */
  .introduction {
    width: 100%;
  }

  .pinwei {
    width: 100%;
  }

  .pinwei dl {
    display: block;
    height: auto;
  }

  .pinwei dl dd {
    width: 100%;
    margin: 0 auto;
  }

  .pinwei dl dd img {
    width: 70%;
    margin: 0 auto;
  }
  .introductionhaed img {
    width: 450px;
  }

  .pinwei dl dd span {
    width: 100%;
    font-size: 40px;
    line-height: 65px;
    box-sizing: border-box;
    padding: 0 40px 20px 40px;
    text-indent: 0;
  }

  .pinwei dl dt {
    width: 90%;
    height: 400px;
    margin: 0 auto;
  }

  .pinwei dl dt img {
    width: 100%;
    margin-top: -250px;
  }

  .jingxuan {
    width: 100%;
  }

  .jingxuan dl {
    display: block;
    height: auto;
    margin-top: 20px;
  }

  .jingxuan dl dd {
    width: 100%;
    margin: 0 auto;
  }

  .jingxuan dl dt img {
    width: 100%;
    height: auto;
    top: 0;
  }
  .displatLeft{
    display: block !important;
    font-size: 36px;
    line-height: 81px;
  }
  .displatLeft span{
    float: left;
    color: #999999;
  }
  .displatLeft .godn{
    /* float: right; */
    margin-left: 47px !important;
    color: #666666;
    width: 924px;
  }
  .jingxuan dl dd span {
    width: 100%;
    font-size: 40px;
    line-height: 48px;
    margin: 20px 40px 20px 40px;
  }

  .jingxuan dl dt {
    width: 90%;
    margin: 0 auto;
  }

  .jingxuan dl dd ul li {
    /* font-size: 24px; */
    /* line-height: 48px; */
    font-size: 40px;
    line-height: 65px;
  }

  .through {
    width: 100%;
    display: block;
  }

  .throughleft {
    width: 100%;
    margin-top: 0;
  }

  .throughleft li {
    /* font-size: 24px; */
    /* line-height: 48px; */
    font-size: 40px;
    line-height: 65px;
  }

  .through div {
    width: 90%;
    margin: 20px auto;
  }

  /* footer */
  /* footer {
    height: 300px;
  } */

  /* .wang span {
    font-size: 24px;
  }

  .erweima {
    top: auto;
    width: auto;
    position: unset;
    right: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .erweima div {
    width: 20%;
  }

  .erweima div img {
    margin: 0 auto;
  }

  .wang {
    display: flex;
    justify-content: center;
    width: auto;
    padding-top: 30px;
  } */
  /* 
  .wang ul li {
    font-size: 26px;
    line-height: 30px;
    width: 30px;
    height: 30px;
  } */

  /* footer p {
    font-size: 20px;
    line-height: 32px;
  }

  .erweima div span {
    font-size: 20px;
  }
  .xuzhi ul li img {
    top: 26px !important;
  } */
}

.headleftl {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.1);
}

.hui {
  display: none;
  width: 100px;
}

.headleftl img {
  width: 34px;
  height: 20px;
  position: relative;
  top: 28px;
  left: 19px;
}

.headleftimg {
  margin-top: 22px;
  margin-left: 40px;
  width: 50px;
  height: 50px;
}

.headleftt span:nth-child(1) {
  margin-left: 12px;
  margin-top: 19px;
  color: #fff;
  font-size: 24px;
  display: block;
  font-weight: 500;
}

.headleftt span:nth-child(2) {
  margin-left: 12px;
  color: #000;
  font-size: 16px;
}

.headcent {
  flex: 1;
  text-align: center;
}

.headcent ul {
  margin-left: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 80px;
}

.headcent ul li:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.cent {
  width: 100%;
  height: auto;
}

.center {
  width: 1288px;
  margin: 0 auto;
  height: auto;
  background: #fff;
}

.visit {
  /* padding-top: 36px; */
  width: 1210px;
  height: 100px;
  margin: 0 auto;
}

.visitcan span:nth-child(1) {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.visitcan span:nth-child(2) {
  padding-left: 10px;
  color: #e70012;
  font-size: 16px;
  font-weight: 500;
}

.visitcan span:nth-child(3) {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid red;
  position: relative;
  top: 15px;
}

.visitlianx {
  color: #999999;
  padding-top: 8px;
  font-size: 17px;
}

.xuanzhe {
  padding-top: 48px;
  padding-left: 30px;
  width: 1180px;
  height: 670px;
  margin: 0 auto;
  border: 1px solid #f6f6f6;
}

.visitcans img {
  position: relative;
  top: 1px;
  padding-left: 8px;
  width: 16px;
  height: 16px;
}

.visitcans span:nth-child(1) {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.visitcans span:nth-child(3) {
  padding-left: 10px;
  color: #e70012;
  font-size: 16px;
  font-weight: 500;
}

.youke {
  margin-top: 48px;
}

.youke span:nth-child(1) {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.youke span:nth-child(2) {
  padding-left: 10px;
  color: #a1a1a1;
  font-size: 16px;
}

.datelist {
  width: 1180px;
}

.datelist ul {
  margin-left: 6px;
  margin-top: 10px;
  /* box-sizing: border-box; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.datelist ul li {
  margin-top: 30px;
  width: 16.66%;
  text-align: center;
}

.datelist ul li div {
  width: 142px;
  height: 78px;
  border: 1px solid #d0d0d0;
  text-align: center;
  border-radius: 15px;
}

.datelist ul li div span:nth-child(1) {
  padding-top: 18px;
  display: block;
  font-size: 14px;
  color: #aaaaaa;
}

.datelist ul li div span:nth-child(2) {
  padding-top: 1px;
  display: block;
  font-size: 18px;
  color: #c19c66;
}

.shuru {
  padding-top: 10px;
  width: 980px;
  height: auto;
  margin: 0 auto;
}

.shutop {
  margin-top: 30px;
  width: 100%;
  display: flex;
}

.shuname {
  width: 50%;
}

.shuname span {
  color: #333333;
  font-size: 16px;
  line-height: 50px;
  position: relative;
  top: 3px;
}

.shuname input {
  margin-left: 15px;
  padding-left: 20px;
  width: 357px;
  height: 52px;
  font-size: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.shuzheng {
  width: 50%;
  position: relative;
}

.shuzheng span {
  color: #333333;
  font-size: 16px;
  line-height: 50px;
  position: relative;
  top: 3px;
}

.shuzheng input {
  margin-left: 15px;
  padding-left: 20px;
  width: 357px;
  font-size: 16px;
  height: 52px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.shuzheng select {
  margin-left: 15px;
  padding-left: 20px;
  width: 357px;
  font-size: 16px;
  height: 52px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #a1a1a1;
}
.xiaLa {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #a1a1a1;
    position: absolute;
    top: 43%;
    left: 80%;
}

.btn {
  /* margin-top: 50px; */
  /* margin-left: 180px; */
  margin: 50px auto 0;
  width: 540px;
  height: 60px;
  background: #e70012;
  border-radius: 5px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 60px;
}

.bord {
  margin: 0 auto;
  margin-top: 40px;
  width: 1210px;
  height: 480px;
  display: flex;
  /* flex-direction: row;
                justify-content: flex-start; */
}

.xuzhi {
  width: 585px;
  height: 480px;

  border: 1px solid #f4f4f4;
  /* background-size: cover; */
  position: relative;
  overflow: hidden;
  /* background: url('../assets/newticket/diwen.png') 100% 100% no-repeat; */
}
.xuzhi ul{
  position:absolute;
  z-index: 100000;
}
.xuzhi .gusu{
  position: absolute;
  bottom: 32px;
  right: -20px;
  z-index: 1;
}
.fuwu {
  margin-left: 35px;
  width: 585px;
  height: 480px;
  /* background: url('../assets/newticket/diwen.png') 100% 100% no-repeat; */
  border: 1px solid #f4f4f4;
  position: relative;
  overflow: hidden;
}
.fuwu .gusu{
  position: absolute;
  bottom: 0;
  bottom: 32px;
  right: -20px;
}
.imgs {
  margin-top: 32px;
  /* width: 214px; */
  margin-left: 178px;
}

.xuzhi ul {
  width: 510px;
  padding-top: 20px;
  padding-left: 20px;
  margin: 0 auto;
}

.xuzhi ul li {
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  display: flex;
}

.xuzhi ul li span {
  padding-left: 15px;
  line-height: 30px;
  width: 478px;
  display: block;
  font-size: 17px;
}

.xuzhi ul li img {
  position: relative;
  top: 7px;
  width: 19px;
  height: 16px;
}

.fuwu div:nth-child(3) {
  padding-top: 32px;
  width: 510px;
  margin: 0 auto;
}

.fuwu div:nth-child(2) {
  padding-top: 42px;
  width: 510px;
  margin: 0 auto;
}

.fuwu div span:nth-child(1) {
  display: block;
  color: #000;
  font-weight: 500;
  font-size: 18px;
}

.fuwu div span:nth-child(2) {
  display: block;
  margin-top: 20px;
  padding-left: 45px;
  color: #666666;
  font-size: 17px;
}

.fuwu div span:nth-child(3) {
  line-height: 35px;
  color: #666666;
  font-size: 17px;
  display: block;
  width: 470px;
  padding-left: 45px;
}

.ditu {
  padding-top: 40px;
  width: 1210px;
  margin: 0 auto;
}

.ditu dl {
  width: 100%;
  display: flex;
}

.ditu img {
  width: 660px;
  height: 368px;
}

.ditu dd {
  flex: 1;
  margin-top: 20px;
  margin-left: 60px;
  z-index: 10000;
  position: relative;
}
.ditu dd  img{
  width: 446px;
  height: 201px;
  margin-top: -150px;
  margin-left: 60px;
}

.ditu dd div:nth-child(1) {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  z-index: 10000;
}

.ditu dd div:nth-child(2) {
  width: 100%;
  margin-top: 45px;
  display: flex;
}

.ditu dd div:nth-child(2) span:nth-child(1) {
  color: #999999;
  font-size: 18px;
}

.ditu dd div:nth-child(2) span:nth-child(2) {
  padding-left: 19px;
  display: block;
  width: 310px;
  color: #000;
  font-size: 16px;
}

.ditu dd div:nth-child(3) {
  width: 100%;
  margin-top: 30px;
  display: flex;
}

.ditu dd div:nth-child(3) span:nth-child(1) {
  color: #999999;
  font-size: 18px;
}

.ditu dd div:nth-child(3) span:nth-child(2) {
  padding-left: 19px;
  display: block;
  width: 310px;
  color: #000;
  font-size: 16px;
}

/* footer {
  width: 100%;
  margin-top: 110px;
} */

.yiman span {
  color: #c3c3c3 !important;
}

.lists div {
  background: url(../assets/newticket/databj.png);
  /*background-color: red ;*/
  background-size: 100%;
}

.lists div span {
  color: #fff !important;
}

.canguan {
  display: none;
  position: fixed;
  left: 50%;
  margin-left: -305px;
  top: 50%;
  height: 439px;
  margin-top: -200px;
  width: 610px;
  /* height: 450px; */
  /*background: url(img/canguanbg.png);*/
  background: url(../assets/newticket/canguanbg.png) no-repeat;
  background-size: 100% 100%;
  z-index: 88888;
  padding-bottom: 70px;
}
.canguan pre{
  font-family: "MicrosoftYaHei";
  line-height: 20px;

}
.canguan img {
  width: 22px;
  height: 22px;
  position: relative;
  top: 20px;
  left: 570px;
}

.canguan h4 {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 20px;
  margin-top: 32px;
}

.canguan ul {
  margin-top: 20px;
  width: 500px;
  margin-left: 55px;
  line-height: 22px;
}

.canguan ul li {
  margin-top: 22px;
  color: #666666;
  font-size: 12px;
}

.canguan span {
  color: #d90112;
}



.successful {
  display: none;
  position: fixed;
  left: 50%;
  margin-left: -195px;
  top: 440px;
  width: 390px;
  height: 150px;
  text-align: center;
  background: url(../assets/newticket/tongzhi.png);
}

.successfuls {
  width: 22px;
  height: 22px;
  position: relative;
  top: 20px;
  left: 160px;
}

.successful h4 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  color: #e70012;
}

.successful span {
  width: 100%;
  text-align: center;
  display: block;
}
.shijianclass {
  /* padding-left: 12px; */
  margin-left: 0 ;
}
.mobile-show {
  display: none;
  width: 200px;
  height: 240px;
}
.mobile-show span{
  display: block;
  height: 200px;
}
.pc-show {
  /* display: flex; */
  /*margin-left: 18px;*/
  margin-left: -12px;
}
.pc-show-time {
  white-space: nowrap;
}
.pc-show :nth-child(1){
  display: inline-block !important;
}
.pc-show .spans {
  color: #b26c30 !important;
  padding-left: 12px;
 display: inline-block;
}
.pc-show span {
  color: #666666 !important;
}
  .dito-i{
    height: 400px;
    line-height: 30px !important;
  }
@media screen and (max-width: 1200px) {
  .mobile-show {
    display: block;
    color: #666666 !important;
        width: 837px;
    height: 405px;
    font-size: 38px;
    /*line-height: 105px;*/
  }
  .subscribe {
    font-size: 42px !important;
  }
  .dito-i{
    height: 400px;
    line-height: 75px !important;
    height: 880px
  }
  .mobile-show span {
    display: block;
    color: #666 !important;
    height: 405px;
    font-size: 42px !important;
  }
  .pc-show {
    display: none;
  }
  .show span {
    width: 300px;
    font-size: 24px;
    line-height: 30px;
  }
  .centhead dl dt ul li span:nth-child(1) {
    width: auto;
    min-width: 200px;
    margin-right: 6px;
}
  .show {
    width: auto;
    height: auto;
    padding: 20px;
  }

  /* 须知 */
  .canguan {
    width: 100%;
    height: auto;
    left: 0;
    margin-left: 0;
    top: 50%  !important;
    margin-top: -206%;
    z-index: 88888;
    background-size: 100% 100%;
    padding-bottom: 20px;
    background-image: url(../assets/newticket/canguanbg.png);
  }

  .canguan ul {
    width: 90%;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
  }

  .canguan img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: auto;
    right: 40px;
  }

  .canguan h4 {
    font-size: 30px;
  }

  .canguan ul li {
    font-size: 24px;
    line-height: 48px;
  }

  .center {
    width: 100%;
  }

  .visit {
    width: 100%;
    margin: 20px 40px;
  }

  .visitcans,
  .youke {
    margin-left: 20px;
  }
  .visitcan span:nth-child(1) {
    /* font-size: 36px; */
    font-size: 52px;
    font-weight: 300;
  }

  .visitcan span:nth-child(2) {
    /* font-size: 30px; */
    font-size: 40px;
  }

  .visitlianx {
    /* font-size: 24px; */
    padding-top: 35px;
    font-size: 36px;
    line-height: 36px;
  }

  .visitcans span:nth-child(1) {
    /* font-size: 36px; */
    font-size: 52px;
    font-weight: 300;
  }

  .visitcans img {
    width: 30px;
    height: 30px;
    top: -15px;
  }

  .visitcans span:nth-child(3) {
    /* font-size: 30px; */
    font-size: 40px;
  }

  .shuru {
    width: 100%;
  }

  .xuanzhe {
    width: 100%;
    height: auto;
    padding-left: 20px;
  }

  /* 移动端 预约须知，讲解服务展示方式 */
  .bord {
    width: 100%;
    height: auto;
    /* display: block; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .xuzhi {
    /* width: 45%; */
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    padding: 10px 70px;
    box-sizing: border-box;
  }

  .fuwu div:nth-child(2) {
    width: 100%;
    padding: 0 40px;
  }

  .fuwu div:nth-child(3) {
    width: 100%;
    padding: 0 40px;
  }

  .fuwu {
    /* width: 45%; */
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    margin: 0 20px;
  }

  .imgs {
    margin-left: 0;
    margin: 20px auto 40px;
    /*margin-top: 20px;*/
    display: block;
    width: 450px;
  }

  .xuzhi .imgs {
    width: 450px;
  }

  .fuwu .imgs {
    /* margin-right: 275px; */
  }

  .fuwu div span:nth-child(3) {
    width: 100%;
    display: inline;
  }

  .xuzhi ul li span {
    /* font-size: 20px; */
    font-size: 36px;
    line-height: 65px;
    width: 100%;
    color: #666666;
  }

  .xuzhi ul {
    width: 100%;
    padding-top: 0;
    padding-left: 80px;
  }
  .xuzhi ul li {
    padding-top: 0;
    padding-left: 80px;
  }

  .ditu dd {
    width: 100%;
    padding-left: 20px;
    /*margin-top: 0;*/
  }

  .ditu dd div:nth-child(1) {
    /* font-size: 32px; */
    font-size: 48px;
  }

  .ditu dd div:nth-child(2) span:nth-child(1),
  .ditu dd div:nth-child(3) span:nth-child(1) {
    font-size: 40px;
    width: 200px;
    min-width: 200px;
  }

  .ditu dd div:nth-child(2) span:nth-child(2) {
    /* font-size: 20px; */
    /* line-height: 30px; */
    font-size: 36px;
    line-height: 52px;
    width: 100%;
    padding-right: 70px;
    color: #666;
  }

  .ditu dd div:nth-child(3) span:nth-child(2) {
    /* font-size: 20px; */
    /* line-height: 30px; */
    font-size: 36px;
    line-height: 52px;
    width: 50%;
    color: #666;
  }

  /* .ditu dd div:nth-child(3) span:nth-child(1) {
                font-size: 24px;
                width: 150px;
            } */

  /* .ditu dd div:nth-child(3) span:nth-child(2) {
                font-size: 20px;
                line-height: 30px;
                width: 100%;
            } */

  .datelist {
    width: 95%;
  }

  .datelist ul li {
    /* width: 25%; */
    width: 33.33%;
  }

  .datelist ul li div {
    width: 80%;
    height: 116px;
    border-radius: 20px;
    margin: 0 auto;
  }

  .datelist ul li div span:nth-child(1) {
    font-size: 30px;
  }
  .datelist ul li div span:nth-child(2) {
    font-size: 32px;
  }
  .huawen-a{
    position: absolute;
    top: -20px;
    z-index: 1000;
  }

  /* 游客信息在移动每行展示一项输入 */
  .shutop {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .shuname,
  .shuzheng {
    width: 100%;
    margin: 35px 80px;
    /*display: flex;*/
    /*justify-content: space-around;*/
  }
  .shuzheng select {
        font-size: 35px;
    }
    .shuzheng select option{
        font-size: 16px;
    }
  .xiaLa {
    top: 50%;
    left: 35%;
}
  .shutop div {
    margin-bottom: 20px;
  }

  /* 移动端 地图展示情况 */
  .ditu dl {
    flex-direction: column;
  }
  #allmap {
    width: 100% !important;
    display: none;
  }
  .ditu {
    padding: 0;
  }
  .youke span:nth-child(1) {
    font-size: 52px;
    font-weight: 300;
  }

  .youke span:nth-child(2) {
    display: block;
    font-size: 36px;
    padding-top: 35px;
  }
  .shuname span,
  .shuzheng span,
  .shuname input,
  .shuzheng input {
    font-size: 36px;
  }

  .shuname input,
  .shuzheng input {
    /*font-size: 50px;*/
    width: 550px;
    height: 70px;
  }

  .fuwu div span:nth-child(1) {
    font-size: 36px;
    font-weight: 300;
  }
  .fuwu div span:nth-child(2),
  .fuwu div span:nth-child(3) {
    font-size: 30px;
    line-height: 55px;
    display: block;
    width: 86%;
  }

  .ditu dd div:nth-child(2) {
    width: 75%;
  }
  .btn {
    font-size: 50px;
    width: 650px;
    height: 120px;
    line-height: 120px;
    /* margin-left: 150px; */
    margin: 0 auto;
  }
  /* mobile 立即预约 */
  .pop-toast {
    width: 10rem;
    padding: 0.8rem;
  }

  .pop-toast .toast-tip {
    font-size: 40px;
  }

  .centhead dl dt ul li:last-child {
    display: none;
  }

  /*--------------------------------*/

  .pinwei dl dt {
    width: 90%;
    height: 500px;
    margin: 0 auto;
  }

  .pinwei dl dt img {
    width: 100%;
    margin-top: 0px;
    height: auto;
  }

  .through div img {
    height: auto;
    width: 100%;
  }
}

A.applink:hover {
  border: 2px dotted #dce6f4;
  padding: 2px;
  background-color: #ffff00;
  color: green;
  text-decoration: none;
}
A.applink {
  border: 2px dotted #dce6f4;
  padding: 2px;
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}
A.info {
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}
A.info:hover {
  color: green;
  background: transparent;
  text-decoration: underline;
}
</style>